<p-confirmPopup></p-confirmPopup>
<app-header-title title="Lista podmiotów">
  <ng-template #actionButtonsTemplate>
    <div class="flex items-center gap-2">
      <span class="p-input-icon-right">
        <i class="pi pi-search" *ngIf="!listLoading && !searchMode" pTooltip="Szukaj" (click)="search" (keyup.enter)="search()" tabindex="1"></i>
        <i class="pi pi-spin pi-spinner" *ngIf="listLoading"></i>
        <!-- x icon clear -->
        <i class="pi pi-times" *ngIf="searchMode" (click)="clearSearch()" pTooltip="Wyczyść" (keyup.enter)="clearSearch()" tabindex="2"></i>
        <input [disabled]="listLoading" pInputText type="text" placeholder="Szukaj" class="border border-gray-300 rounded-md p-3" [(ngModel)]="searchText" (keyup.enter)="search()" tabindex="0"/>
      </span>
      <p-button label="Dodaj podmiot" icon="pi pi-plus" (onClick)="showCreateDialog()"></p-button>
    </div>
  </ng-template>
</app-header-title>
<div class="flex flex-wrap gap-2 bg-white dark:bg-gray-800 rounded-xl mt-4 p-2 py-4 shadow-md mx-2">
  <p-table [value]="filteredCredentials" class="mb-4 z-index-20 w-full"
  [paginator]="true"
  [rows]="15"
  [showCurrentPageReport]="true"
  styleClass="p-datatable-striped p-datatable-gridlines"
  responsiveLayout="stack"
  [breakpoint]="'960px'"
  [loading]="loading"
  >
    <ng-template pTemplate="header">
      <tr>
          <th scope="col">Nazwa</th>
          <th scope="col">ClientId</th>
          <th scope="col">ClientSecret</th>
          <th scope="col">Akcje</th>
      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-credentials>
      <tr>
          <td [ngClass]="{'flex flex-col items-start': isHandset$ | async}">
            <span class="p-column-title font-semibold">Nazwa</span>
            <div class="flex flex-row items-center gap-2">
              <img
              src="https://ui-avatars.com/api/?size=128&background=random&name={{
                credentials?.name
              }}"
              style="width: 18px"
              [alt]="credentials?.name"
            />
            <p>
              {{ credentials.name }}
            </p>
            </div>
            </td>
          <td [ngClass]="{'flex flex-col items-start': isHandset$ | async}">
            <span class="p-column-title font-semibold">ClientID</span>
            <p class="font-mono text-sm">{{ credentials.clientId }}</p>
          </td>
          <td [ngClass]="{'flex flex-col items-start': isHandset$ | async}">
            <span class="p-column-title font-semibold">ClientSecret</span>
            <app-secret-value-field [value]="credentials.clientSecret"></app-secret-value-field></td>
          <td [ngClass]="{'flex flex-col items-start': isHandset$ | async}">
            <div class="flex flex-row gap-2">
              <p-button label="Edytuj" icon="pi pi-pencil" (onClick)="showEditDialog(credentials)"></p-button>
              <p-button label="Usuń" icon="pi pi-trash" severity="danger" (onClick)="removeCredentials($event, credentials)" [loading]="getRemoveLoading(credentials)"></p-button>
            </div>
          </td>
      </tr>
  </ng-template>
  </p-table>
</div>
