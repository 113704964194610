import { ActivatedPackage } from "./activated-package.model"
import { BdoCredentials } from "./bdo-credentials.model"
import { Profile } from "./profile.model"

export class EmployeeProfile implements Profile{

  constructor(
    readonly username: string,
    readonly email: string,
    readonly firstName: string,
    readonly lastName: string,
    readonly companyId: string,
    readonly companyName: string,
    readonly activatedPackage: ActivatedPackage,
    readonly credentials: BdoCredentials[],
  ) {}

  getFullName(): string{
    return `${this.firstName} ${this.lastName}`
  }

  hasActivePackage(): boolean {
    return this.activatedPackage?.activated ?? false
  }

}
