import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  static getAccountType(roles: string[]): AccountType {
    if (roles.includes('COMPANY')) {
      return AccountType.COMPANY;
    } else if (roles.includes('EMPLOYEE')) {
      return AccountType.EMPLOYEE;
    }

    return null;
  }
}


export enum AccountType{
  EMPLOYEE = "EMPLOYEE",
  COMPANY = "COMPANY"
}
