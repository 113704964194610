import { Injectable } from '@angular/core';
import { EvidenceCredentials } from './evidence-credentials.model';
import { CompanyContext } from 'src/app/common/ui/company-context-select/models/company-context.model';

const ACCESS_TOKEN = 'access_token'
const REFRESH_TOKEN = 'refresh_token'
const EVIDENCE_CREDENTIALS = 'evidence_credentials'
const COMPANY_CONTEXT = 'company_context'

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  getToken(): string {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  getRefreshToken(): string {
    return localStorage.getItem(REFRESH_TOKEN);
  }

  saveToken(token): void {
    localStorage.setItem(ACCESS_TOKEN, token);
  }

  saveRefreshToken(refreshToken): void {
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  }

  removeToken(): void {
    localStorage.removeItem(ACCESS_TOKEN);
  }

  removeRefreshToken(): void {
    localStorage.removeItem(REFRESH_TOKEN);
  }

  removeCompanyContext() {
    localStorage.removeItem(COMPANY_CONTEXT);
  }

  saveEvidenceCredentials(credentials: EvidenceCredentials): void {
    localStorage.setItem(EVIDENCE_CREDENTIALS, JSON.stringify(credentials))
  }

  getEvidenceCredentials(): EvidenceCredentials {
    return JSON.parse(localStorage.getItem(EVIDENCE_CREDENTIALS));
  }

  getCompanyContext(): CompanyContext {
    return  JSON.parse(localStorage.getItem(COMPANY_CONTEXT)) as CompanyContext;
  }

  saveCompanyContext(companyContext: CompanyContext): void {
    localStorage.setItem(COMPANY_CONTEXT, JSON.stringify(companyContext))
  }
}
