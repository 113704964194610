import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, isDevMode, APP_INITIALIZER, NgModule, ErrorHandler } from '@angular/core';
import '@angular/common/locales/global/pl'
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { DateInterceptor } from './services/bdoservice/common/interceptors/date-interceptor';
import { DashboardModule } from './dashboard/dashboard.module';
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.keycloakUrl,
        realm: environment.realm,
        clientId: environment.clientId
      },
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: false
      },
      shouldAddToken: (request) => {
        if (request.url.endsWith("bdomobile.json")) {
          return false;
        }
        return true;
      }
    });
}


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    KeycloakAngularModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    FontAwesomeModule,
    GoogleTagManagerModule,
    MessagesModule,
    ToastModule,
    DashboardModule
  ],
  providers: [
      {
      provide: LOCALE_ID,
      useValue: 'pl-PL'
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: 'googleTagManagerId',
      useValue: environment.googleTagManagerId
    },
    { provide: 'googleTagManagerPreview',  useValue: environment.googleTagManagerPreview },
    { provide: 'googleTagManagerAuth',  useValue: environment.googleTagManagerAuth },
    { provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
