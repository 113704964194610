import { Injectable } from '@angular/core';
import { CompanyProfile, Profile, UsersService } from './bdoservice/users/users.service';
import { Subject, BehaviorSubject } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { AccountType } from '../auth.service';
import { KeycloakRole } from './bdoservice/common/model/keycloak-role.model';
import { EmployeeProfile } from './bdoservice/users/models/employee-profile.model';
import { ActivatedPackage } from './bdoservice/users/models/activated-package.model';
import * as Sentry from "@sentry/angular-ivy";

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
    readonly usersService: UsersService,
    readonly keycloakService: KeycloakService,
  ){}

  private profile: Subject<ProfileState> = new BehaviorSubject<ProfileState>(null);
  public profile$ = this.profile.asObservable();

  private activePackage: Subject<ActivatedPackage | null> = new BehaviorSubject<ActivatedPackage | null>(null);
  public activePackage$ = this.activePackage.asObservable();

  getAccountType(): AccountType {
    return this.keycloakService.getUserRoles().includes(KeycloakRole.COMPANY) ? AccountType.COMPANY : AccountType.EMPLOYEE;
  }

  refreshPackage(){
    this.usersService.getProfile(this.getAccountType()).then(profile => {
      console.log("refreshing package");
      this.activePackage.next(
        this.getActivePackage(profile, this.getAccountType())
      );
    })
  }

  loadProfile(){
    this.profile.next({
      loading: true,
    });

    if(!this.keycloakService.isLoggedIn()){
      return;
    }

    this.keycloakService.loadUserProfile().then( profile => {

      Sentry.setUser({
        email: profile.email,
        username: profile.username,
        id: profile.id,
      });

      if(profile['attributes'].marketingAgreement == null && this.keycloakService.getUserRoles().includes(KeycloakRole.COMPANY)){

        const emptyProfile = {
          firstName: profile.firstName,
          lastName: profile.lastName,
          email: profile.email,
        } as Profile;



        this.profile.next(
          {
            loading: false,
            requireFirstConfiguration: true,
            data: emptyProfile,
          }
        );

        this.activePackage.next(null);
      } else {
        const accountType = this.keycloakService.getUserRoles().includes('COMPANY') ? AccountType.COMPANY : AccountType.EMPLOYEE;

        this.usersService.getProfile(accountType).then(profile => {
          this.profile.next(
            {
              loading: false,
              requireFirstConfiguration: false,
              data: this.buildProfile(profile, accountType),
            }
          );

          this.activePackage.next(
            this.getActivePackage(profile, accountType)
          );

        }).catch(err => {
          this.profile.next(
            {
              loading: false,
              error: err,
            }
          );
        });
      }
    }
  );
  }

  getActivePackage(profile: Profile, accountType: AccountType): ActivatedPackage | null {
    if(accountType === AccountType.COMPANY){
      const companyProfile = profile as CompanyProfile;
      return companyProfile.packages.find(x => x.activated);
    } else if(accountType === AccountType.EMPLOYEE){
      const employeeProfile = profile as EmployeeProfile;
      return employeeProfile.activatedPackage;
    } else {
      return null;
    }
  }

  private buildProfile(profile: Profile, accountType: AccountType): Profile{
    switch(accountType){
      case AccountType.COMPANY:
        return this.getCompanyProfile(profile);
      case AccountType.EMPLOYEE:
        return this.getEmployeeProfile(profile);

    }
  }

  private getEmployeeProfile(profile: Profile): EmployeeProfile{
    const originalProfile = profile as EmployeeProfile;
        return new EmployeeProfile(
          originalProfile.username,
          originalProfile.email,
          originalProfile.firstName,
          originalProfile.lastName,
          originalProfile.companyId,
          originalProfile.companyName,
          originalProfile.activatedPackage,
          originalProfile.credentials,
        )
  }

  private getCompanyProfile(profile: Profile): CompanyProfile{
    const originalProfile = profile as CompanyProfile;
        return new CompanyProfile(
          originalProfile.username,
          originalProfile.email,
          originalProfile.firstName,
          originalProfile.lastName,
          originalProfile.companyName,
          originalProfile.packages,
          originalProfile.mainAccountCreated,
          originalProfile.credentials,
          originalProfile.marketingAgreement,
          originalProfile.companyInvoice,
          originalProfile.personInvoice,
          originalProfile.authorities,
        )
  }
}

interface ProfileState {
  loading: boolean;
  requireFirstConfiguration?: boolean | null;
  error?: Error | null;
  data?: Profile;
}
