import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BdoCredentials } from 'src/app/services/bdoservice/users/models/bdo-credentials.model';
import { EupItem } from 'src/app/services/bdoservice/users/models/eup-item.model';
import { GetEupListRequest } from 'src/app/services/bdoservice/users/models/get-eup-list-request.model';
import { Profile, UsersService } from 'src/app/services/bdoservice/users/users.service';
import { CompanyContext } from './models/company-context.model';
import { TokenService } from 'src/app/services/token/token.service';
import { Role } from 'src/app/services/bdoservice/wasterecord/models/search-card.model';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { InputSwitchChangeEvent } from 'primeng/inputswitch';

@Component({
  selector: 'app-company-context-select',
  templateUrl: './company-context-select.component.html',

})
export class CompanyContextSelectComponent implements OnInit, OnChanges {

  @Input()
  public companyProfile: Profile;

  @Input()
  public roleSelectable = false;

  @Input()
  public disabled = false;

  @Input()
  public availableRoles: Role[] = [Role.SENDER, Role.CARRIER, Role.RECEIVER]

  @Output()
  public errorEvent: EventEmitter<unknown> = new EventEmitter<unknown>();

  public eups: EupItem[];

  public hideInactiveEups = true;

  loadingEups = true;

  roles = [
    new RoleDropdownItem(Role.SENDER, 'Przekazujący'),
    new RoleDropdownItem(Role.CARRIER, 'Transportujący'),
    new RoleDropdownItem(Role.RECEIVER, 'Przejmujący')
  ];

  public selectedBdoCredentials: BdoCredentials;

  @Output()
  public selectedBdoCredentialsChange = new EventEmitter<BdoCredentials>();

  public selectedEup: EupItem;

  public selectedEupError: string | null = null;

  @Output()
  public selectedEupChange = new EventEmitter<EupItem>();

  public selectedRole: RoleDropdownItem;

  @Output()
  public selectedRoleChange = new EventEmitter<Role>();

  filteredRoles = [];

  ngOnInit(): void {
    this.filterRoles();
    this.restoreState();
  }

  filterRoles(){
    this.filteredRoles = this.roles.filter(x => this.availableRoles.includes(x.value));
  }

  setAfterCompanyProfileChange(){
    if(!this.companyProfile){
      return;
    }


    if(!this.filterRoles || this.filterRoles.length === 0){
      this.filterRoles();
    }

    const companyContext = this.tokenService.getCompanyContext();

    if(companyContext && (companyContext.schemaVersion !== CompanyContext.SCHEMA_VERSION || companyContext.username !== this.companyProfile.username || this.companyProfile?.credentials?.findIndex(x => x.id === companyContext.companyId) === -1)){
      this.tokenService.removeCompanyContext();
    }

    const credentialsIndex = this.companyProfile?.credentials?.findIndex(x => x.id  === companyContext?.companyId);
    if(companyContext && credentialsIndex !== -1){
      this.selectedBdoCredentials = this.companyProfile?.credentials[credentialsIndex];
      this.selectedRole = this.filteredRoles.find(x => x.value === companyContext.role);
    } else {
      if(this.companyProfile?.credentials?.length > 0){
        const newCompanyContext = new CompanyContext(this.companyProfile.username, this.companyProfile?.credentials[0].id, companyContext?.eupId ?? '', companyContext?.role ?? Role.SENDER)
        this.tokenService.saveCompanyContext(newCompanyContext);
        this.selectedBdoCredentials = this.companyProfile?.credentials[0];
      }

      this.selectedRole = companyContext?.role ?? this.filteredRoles[0];
    }

    this.selectedBdoCredentialsChange.emit(this.selectedBdoCredentials);
    this.loadEups();
  }

  onCredentialChange(event: DropdownChangeEvent) {
    this.selectedBdoCredentials = event.value;
    this.selectedBdoCredentialsChange.emit(event.value);
    this.loadEups();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChangeHideInactiveEups(event: InputSwitchChangeEvent){
    this.loadEups(!event.checked);
  }

  constructor(
    private readonly usersService: UsersService,
    private readonly tokenService: TokenService
    ) {
    }

  loadEups(showInactive = true){
    //if actual is inactive then change to first active
    this.selectedEupError = null;

    const id = this.getSelectedCredentialsId();

    if(id){
      const request = new GetEupListRequest(id);

      this.usersService.getEupList(request).then(res => {
        this.eups = this.filterEups(res, showInactive);

        const companyContext = this.tokenService.getCompanyContext();

        if(companyContext && companyContext.companyId === this.selectedBdoCredentials.id && companyContext.eupId !== ''){
          this.selectedEup = this.getSelectedEup(companyContext.eupId, showInactive);
          if(!this.selectedEup){
            this.selectedEup = this.eups[0];
            const newCompanyContext = new CompanyContext(this.companyProfile.username, this.selectedBdoCredentials.id, this.selectedEup.eupId, companyContext.role ?? Role.SENDER);
            this.tokenService.saveCompanyContext(newCompanyContext);
            this.selectedEupChange.emit(this.selectedEup);
          }
        } else {
          const eupItem = this.getSelectedEup(companyContext?.eupId, showInactive) ?? this.eups[0];
          this.selectedEup = eupItem;
          const newCompanyContext = new CompanyContext(this.companyProfile.username, this.selectedBdoCredentials.id, eupItem.eupId, companyContext.role ?? Role.SENDER);
          this.tokenService.saveCompanyContext(newCompanyContext);
          this.selectedEupChange.emit(eupItem);
        }
      }).catch(reason => {
        this.selectedEupError = 'Nie udało się pobrać listy MPD.';
        this.errorEvent.emit(reason);
      });
    }
  }

  private getSelectedCredentialsId(): string {
    return this.selectedBdoCredentials?.id ?? this.companyProfile?.credentials[0].id;
  }

  private filterEups(eups: EupItem[], showInactive: boolean): EupItem[] {
    return eups.filter(x => showInactive || x.isActive);
  }

  private getSelectedEup(eupId: string, showInactive: boolean): EupItem | undefined {
    return this.eups.find(eup => eup.eupId === eupId && (showInactive || eup.isActive));
  }

  onChangeBdoCredentials(event: DropdownChangeEvent){
    this.selectedBdoCredentials = event.value;
    this.selectedEup = null;
    this.selectedEupError = null;
    this.loadEups();
  }

  onChangeBdoEup(event: DropdownChangeEvent){
    this.selectedEup = event.value;
    this.saveCompanyContext();
    this.selectedEupChange.emit(this.selectedEup);
  }

  onChangeRole(event: DropdownChangeEvent){
    this.selectedRole = event.value;
    this.saveCompanyContext();
    this.selectedRoleChange.emit(this.selectedRole.value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.companyProfile && changes.companyProfile.currentValue){
      this.setAfterCompanyProfileChange();
      this.loadEups();
    }
  }

  saveCompanyContext(){
    const companyContext = new CompanyContext(this.companyProfile.username, this.selectedBdoCredentials.id, this.selectedEup?.eupId ?? '', this.selectedRole?.value);
    this.tokenService.saveCompanyContext(companyContext);
  }

  restoreState(){
    const companyContext = this.tokenService.getCompanyContext();
    if(companyContext){
      this.selectedBdoCredentials = this.companyProfile?.credentials.find(x => x.id === companyContext.companyId);
      this.selectedRole = this.filteredRoles.find(x => x.value === companyContext.role) ?? this.filteredRoles[0];
    }
  }
}

export class RoleDropdownItem{
  constructor(
    readonly value: Role,
    readonly label: string
  ){}
}
