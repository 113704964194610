<div class="flex flex-col gap-4 ">
  <div>
    <form [formGroup]="form" class="flex flex-col gap-2">
      <div class="flex flex-col gap-2">
        <label for="firstName">Imię<span title="required" class="text-red-600">*</span></label>
        <input pInputText  formControlName="firstName" [ngClass]="{'ng-invalid ng-dirty': form.controls.firstName.invalid && form.controls.firstName.touched}"/>
        <small class="text-red-500" *ngIf="form.controls.firstName.invalid && form.controls.firstName.touched">
          {{ getFirstNameError() }}
        </small>
      </div>
      <div class="flex flex-col gap-2">
        <label for="lastName">Nazwisko<span  title="required" class="text-red-600">*</span></label>
        <input pInputText  formControlName="lastName" [ngClass]="{'ng-invalid ng-dirty': form.controls.lastName.invalid && form.controls.lastName.touched}"/>
        <small class="text-red-500" *ngIf="form.controls.lastName.invalid && form.controls.lastName.touched">
          {{ getLastNameError() }}
        </small>
      </div>
      <div class="flex flex-col gap-2">
        <label for="username">Nazwa użytkownika<span title="required" class="text-red-600">*</span></label>
        <input pInputText  formControlName="username"  [ngClass]="{'ng-invalid ng-dirty': form.controls.username.invalid && form.controls.username.touched}"/>
        <small class="text-red-500" *ngIf="form.controls.username.invalid && form.controls.username.touched">
          {{ getUsernameError() }}
        </small>
      </div>
      <div class="flex flex-col gap-2" *ngIf="!isEdit()">
        <label for="password">Hasło<span title="required" class="text-red-600">*</span></label>
        <p-password class="w-full" [feedback]="true"
        styleClass="p-password p-component p-inputwrapper p-input-icon-right" [style]="{'width':'100%'}"
        [inputStyle]="{'width':'100%'}" [toggleMask]="true" formControlName="password" [ngClass]="{'ng-invalid ng-dirty': form.controls.password.invalid && form.controls.password.touched}"  />
        <small class="text-red-500" *ngIf="form.controls.password.invalid && form.controls.password.touched">
          {{ getPasswordError() }}
        </small>
      </div>
      <div class="flex flex-col gap-2" *ngIf="!isEdit()">
        <label for="password2">Hasło<span title="required" class="text-red-600">*</span></label>
        <p-password class="w-full" class="w-full" [feedback]="false"
        styleClass="p-password p-component p-inputwrapper p-input-icon-right" [style]="{'width':'100%'}"
        [inputStyle]="{'width':'100%'}" [toggleMask]="true" formControlName="password2" [ngClass]="{'ng-invalid ng-dirty': form.controls.password2.invalid && form.controls.password2.touched}"/>
        <small class="text-red-500" *ngIf="form.controls.password2.invalid && form.controls.password2.touched">
          {{ getPassword2Error() }}
        </small>
      </div>
      <h2 class="text-xl font-semibold">Podmioty</h2>
      <p-pickList [source]="allCredentials" [target]="credentials" sourceHeader="Dostępne" targetHeader="Wybrane" [dragdrop]="true" [responsive]="true" [sourceStyle]="{ height: '30rem' }" [targetStyle]="{ height: '30rem' }" filterBy="name" sourceFilterPlaceholder="Szukaj..." targetFilterPlaceholder="Szukaj..." breakpoint="1400px">
      <ng-template let-credential pTemplate="item">
          <div class="flex flex-wrap p-2 align-items-center gap-3">
              <img class="w-8 h-8" src="https://ui-avatars.com/api/?size=128&background=random&name={{credential.name}}" alt="{item.name}" />
              <div class="flex-1 flex flex-column gap-2">
                  <span class="font-bold">{{ credential.name }}</span>
              </div>
          </div>
      </ng-template>
      </p-pickList>
      <p class="text-red-500"*ngIf="formError">{{ formError }}</p>
    </form>
  </div>
  <div class="flex gap-2 mt-4">
    <p-button *ngIf="!isFirstConfiguration()" (onClick)="onNoClick()" type="button" label="Zamknij"/>
    <p-button *ngIf="!isFirstConfiguration()" (onClick)="saveForm()" [disabled]="!form.valid" type="button" label="Zapisz"/>
  </div>

</div>
