export abstract class AccountValidators {
  static NIP_PATTERN = "^\\d{10}$";
  static MIN_PASSWORD_LENGTH = 8;
  static MAX_PASSWORD_LENGTH = 32;
  static USERNAME_PATTERN = "^[a-zA-Z0-9_.]+$";
  static MIN_CREDENTIALS_NAME_LENGTH = 4;
  static POST_CODE_PATTERN = "^[0-9]{2}-[0-9]{3}$";
}

export abstract class ClientSecretsValidators{
  static CLIENT_ID_PATTERN = "^\\s*[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\\s*$";
  static CLIENT_SECRET_PATTERN = "^\\s*[0-9a-f]{64}\\s*$";
}
