import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AccountValidators, ClientSecretsValidators } from 'src/app/services/bdoservice/constants';
import { AddBdoCredentialsRequest } from 'src/app/services/bdoservice/users/models/add-bdo-credentials-request.model';
import { BdoCredentials } from 'src/app/services/bdoservice/users/models/bdo-credentials.model';
import { UsersService, EditBdoCredentialsRequest } from 'src/app/services/bdoservice/users/users.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';

export class CredentialsDialogData {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly clientId: string,
    readonly clientSecret: string,
    readonly operationType: CredentialOperationType = CredentialOperationType.CREATE_CREDENTIAL){}
}

export enum CredentialOperationType{
  EDIT_CREDENTIAL = "EDIT_CREDENTIAL",
  CREATE_CREDENTIAL = "CREATE_CREDENTIAL"
}

@Component({
  selector: 'app-add-credentials',
  templateUrl: './add-credentials.component.html',

})
export class AddCredentialsComponent implements OnInit {
  form: FormGroup;
  formError = '';
  loading = false;

  constructor(
    public readonly ref: DynamicDialogRef,
    public readonly config: DynamicDialogConfig<CredentialsDialogData>,
    private usersService: UsersService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService) {}

    ngOnInit(){
      this.form = new FormGroup({
        name: new FormControl(this.config.data?.name, [Validators.required, Validators.minLength(AccountValidators.MIN_CREDENTIALS_NAME_LENGTH)]),
        clientId: new FormControl(this.config.data?.clientId, [Validators.required, Validators.pattern(ClientSecretsValidators.CLIENT_ID_PATTERN)]),
        clientSecret: new FormControl(this.config.data?.clientSecret, [Validators.required, Validators.pattern(ClientSecretsValidators.CLIENT_SECRET_PATTERN)])
      });
    }

  onNoClick(): void {
    this.ref.close();
  }

  private saveCredential(data: BdoCredentials){
    this.loading = true;

    const request = new AddBdoCredentialsRequest(
      data.name,
      data.clientId.trim(),
      data.clientSecret.trim()
    )

    this.usersService.addBdoCredentials(request).then(() => {
      this.messageService.add({severity:'success', summary: 'Sukces', detail: 'Podmiot został zapisany.'});

      this.ref.close()
    }).catch(err => {
      this.setFormError(err.message || 'Wystąpił nieoczekiwany błąd.')
    }).finally(() => {
      this.loading = false;
    });
  }

  saveForm(){
    this.setFormError(null)
    if(this.isEdit() === true){
      this.editCredential(this.form.getRawValue());
    } else {
      this.saveCredential(this.form.getRawValue());
    }
  }

  private isEdit(): boolean {
    return this.config.data?.operationType === CredentialOperationType.EDIT_CREDENTIAL;
  }

 private editCredential(credentialsForm: BdoCredentials){
    this.loading = true;

    const request = new EditBdoCredentialsRequest(
      this.config.data?.id,
      credentialsForm.name,
      credentialsForm.clientId.trim(),
      credentialsForm.clientSecret.trim()
    )

    this.usersService.editBdoCredentials(request).then(() => {
      this.messageService.add({severity:'success', summary: 'Sukces', detail: 'Podmiot został zapisany.'});
      this.ref.close()
    }).catch(err => {
      this.setFormError(err.message || 'Wystąpił nieoczekiwany błąd.')
    }).finally(() => {
      this.loading = false;
    });
  }

  getError(fieldName: string): string {
    const field = this.form.get(fieldName);
    if (field.hasError('required')) {
      return 'Pole jest wymagane.';
    } else if (field.hasError('minlength')) {
      return `Pole musi składać się z min. ${AccountValidators.MIN_CREDENTIALS_NAME_LENGTH} znaków.`;
    } else if (field.hasError('pattern')) {
      return 'Pole musi być wypełnione w odpowiednim formacie.';
    }
    return '';
  }

  setFormError(message: string){
    this.formError = message;
  }
}
