<p-steps
  *ngIf="(isHandset$ | async) === false"
  [model]="items"
  [readonly]="false"
  styleClass="mb-4"
  [activeIndex]="activeIndex"
></p-steps>
<div *ngIf="isHandset$ | async" class="flex gap-3 items-center mb-4">
  <!-- circle with number of step-->
  <span class="w-8 h-8 rounded-full bg-green-700 text-white flex align-middle items-center justify-center">
    {{ activeIndex+1 }}
  </span>
  <h3 class="text-xl font-semibold">{{ items[activeIndex].label }}</h3>
</div>
<div *ngIf="activeIndex === 0">
  <div class="flex flex-col gap-4">
      <img class="items-center w-60 self-center justify-center max-w-xs" src="../../../assets/logo.png" alt="BDO mobile logo" />
      <h2 class="text-xl font-semibold text-center mb-4">Witamy w BDO mobile!</h2>
      <p>
        Dziękujemy za założenie konta w naszym systemie. Przed pierwszym uruchomieniem aplikacji mobilnej konieczne jest przeprowadzenie wstępnej konfiguracji konta. W kolejnych krokach możesz dodać podmiot i stworzyć pierwszego użytkownika mobilnego, co umożliwi Ci pełne korzystanie z aplikacji na telefonie.
      </p>
      <p-button
        (onClick)="onNext()"
        color="primary"
        aria-label="Przejdź dalej"
        class="self-center mt-4"
      >
        <i class="pi pi-flag"></i>  Zaczynamy!
      </p-button>
  </div>
</div>
<div *ngIf="activeIndex === 1">
  <p class="mb-8">Chcielibyśmy dowiedzieć się więcej o Twojej firmie oraz miejscu, w którym po raz pierwszy usłyszałeś o nas.</p>
  <form [formGroup]="accountForm" class="flex flex-col gap-4">
    <div class="flex flex-col gap-2">
        <label for="companyName">Nazwa firmy<span *ngIf="isRequired(accountForm, 'companyName')" title="required" class="text-red-600">*</span></label>
        <input type="text" pInputText formControlName="companyName" class="w-full" />
        <small class="text-red-500" *ngIf="!accountForm.controls.companyName.valid && accountForm.controls.companyName.touched">
            {{ getCompanyNameError() }}
        </small>
    </div>

    <div class="flex flex-col gap-2">
        <label for="marketingSource">Skąd dowiedziałeś się o aplikacji?<span *ngIf="isRequired(accountForm, 'marketingSource')" title="required" class="text-red-600">*</span></label>
        <p-dropdown [options]="marketingSource" formControlName="marketingSource" class="w-full" required appendTo="body"></p-dropdown>
    </div>

    <div *ngIf="accountForm.controls.marketingSource.value === 'Inne'" class="flex flex-col gap-2">
        <label for="marketingSourceOther">Napisz skąd wiesz o naszej aplikacji<span *ngIf="isRequired(accountForm, 'marketingSourceOther')" title="required" class="text-red-600">*</span></label>
        <input type="text" pInputText formControlName="marketingSourceOther" class="w-full" [required]="accountForm.controls.marketingSource.value === 'Inne'" />
        <small class="text-red-500" *ngIf="!accountForm.controls.marketingSourceOther.valid && accountForm.controls.marketingSourceOther.touched">
            {{ getMarketingSourceOtherError() }}
        </small>
    </div>

    <div class="flex flex-col gap-2">
        <p-checkbox formControlName="marketingAgreement" [binary]="true" label="Chcę otrzymywać newsletter - wiadomości o nowościach, produktach i usługach związanych z ochroną środowiska w biznesie."></p-checkbox>
    </div>

    <!-- Chip group dodania aliasów -->
    <p class="text-red-500" *ngIf="accountFormError">{{ accountFormError }}</p>
    <p-button
        color="primary"
        label="Zapisz"
        (onClick)="saveAccountForm()"
        [loading]="accountFormLoading"
        [disabled]="!accountForm.valid"
      />
  </form>
</div>
<div *ngIf="activeIndex === 2">
  <p>
    Za pomocą poniższego formularza możesz wprowadzić dane do podmiotu, którym
    chcesz zarządzać z poziomu aplikacji. Możesz pominąć ten krok i dodać
    podmiot później w liście użytkowników.
    <br/><br/>
  </p>
  <p>
    Jeżeli masz trudności z integracją, możesz skorzystać z instrukcji lub obejrzeć film instruktażowy.<br/><br/> W przypadku problemów z integracją, prosimy o kontakt z naszym działem wsparcia.
  </p>
  <app-create-company-instructions />
  <form [formGroup]="credentialsForm" class="flex flex-col gap-4">
    <div class="flex flex-col gap-2">
        <label for="name">Nazwa<span *ngIf="isRequired(credentialsForm, 'name')" title="required" class="text-red-600">*</span></label>
        <input type="text" pInputText formControlName="name" class="w-full" required />
        <small class="text-red-500" *ngIf="!credentialsForm.controls.name.valid && credentialsForm.controls.name.touched">
            {{ getCredentialsNameError() }}
        </small>
    </div>

    <div class="flex flex-col gap-2">
        <label for="clientId">Client Id<span *ngIf="isRequired(credentialsForm, 'clientId')" title="required" class="text-red-600">*</span></label>
        <input type="text" pInputText formControlName="clientId" class="w-full" required />
        <small class="text-red-500" *ngIf="!credentialsForm.controls.clientId.valid && credentialsForm.controls.clientId.touched">
            {{ getClientIdError() }}
        </small>
    </div>

    <div class="flex flex-col gap-2">
        <label for="clientSecret">Client Secret<span *ngIf="isRequired(credentialsForm, 'clientSecret')" title="required" class="text-red-600">*</span></label>
        <input type="text" pInputText formControlName="clientSecret" class="w-full" required />
        <small class="text-red-500" *ngIf="!credentialsForm.controls.clientSecret.valid && credentialsForm.controls.clientSecret.touched">
            {{ getClientSecretError() }}
        </small>
    </div>

    <p class="text-red-500" *ngIf="credentialsFormError">{{ credentialsFormError }}</p>
    <div class="flex gap-4">
        <p-button
            label="Pomiń"
            [text]="true"
            (onClick)="skipCredentialsForm()"
            [loading]="credentialsFormLoading"
        ></p-button>
        <p-button
            color="primary"
            label="Zapisz"
            (onClick)="saveCredentialsForm()"
            [loading]="credentialsFormLoading"
            [disabled]="!credentialsForm.valid"
        ></p-button>
    </div>
</form>
</div>
<div *ngIf="activeIndex === 3">
  <ng-template
    >Stwórz użytkownika mobilnego</ng-template
  >
  <p class="mb-8">
    Wprowadź dane pierwszego użytkownika, za pomocą którego będziesz mógł się
    zalogować do aplikacji mobilnej.
  </p>
  <div class="flex flex-col gap-4">
    <div>
      <form [formGroup]="createUserForm" class="flex flex-col gap-2">
        <div class="flex flex-col gap-2">
          <label for="firstName"
            >Imię<span title="required" class="text-red-600">*</span></label
          >
          <input
            pInputText
            formControlName="firstName"
            [ngClass]="{
              'ng-invalid ng-dirty':
                !createUserForm.controls.firstName.valid &&
                createUserForm.controls.firstName.touched
            }"
          />
          <small
            class="text-red-500"
            *ngIf="
              !createUserForm.controls.firstName.valid &&
              createUserForm.controls.firstName.touched
            "
          >
            {{ getFirstNameError() }}
          </small>
        </div>
        <div class="flex flex-col gap-2">
          <label for="lastName"
            >Nazwisko<span title="required" class="text-red-600">*</span></label
          >
          <input
            pInputText
            formControlName="lastName"
            [ngClass]="{
              'ng-invalid ng-dirty':
                !createUserForm.controls.lastName.valid &&
                createUserForm.controls.lastName.touched
            }"
          />
          <small
            class="text-red-500"
            *ngIf="
              !createUserForm.controls.lastName.valid &&
              createUserForm.controls.lastName.touched
            "
          >
            {{ getLastNameError() }}
          </small>
        </div>
        <div class="flex flex-col gap-2">
          <label for="username"
            >Nazwa użytkownika<span title="required" class="text-red-600"
              >*</span
            ></label
          >
          <input
            pInputText
            formControlName="username"
            [ngClass]="{
              'ng-invalid ng-dirty':
                !createUserForm.controls.username.valid &&
                createUserForm.controls.username.touched
            }"
          />
          <small
            class="text-red-500"
            *ngIf="
              !createUserForm.controls.username.valid &&
              createUserForm.controls.username.touched
            "
          >
            {{ getUsernameError() }}
          </small>
        </div>
        <div class="flex flex-col gap-2">
          <label for="password"
            >Hasło<span title="required" class="text-red-600">*</span></label
          >
          <p-password
            class="w-full"
            [feedback]="true"
            styleClass="p-password p-component p-inputwrapper p-input-icon-right"
            [style]="{ width: '100%' }"
            [inputStyle]="{ width: '100%' }"
            [toggleMask]="true"
            formControlName="password"
            [ngClass]="{
              'ng-invalid ng-dirty':
                !createUserForm.controls.password.valid &&
                createUserForm.controls.password.touched
            }"
          />
          <small
            class="text-red-500"
            *ngIf="
              !createUserForm.controls.password.valid &&
              createUserForm.controls.password.touched
            "
          >
            {{ getPasswordError() }}
          </small>
        </div>
        <div class="flex flex-col gap-2">
          <label for="password2"
            >Powtórz hasło<span title="required" class="text-red-600">*</span></label
          >
          <p-password
            class="w-full"
            class="w-full"
            [feedback]="false"
            styleClass="p-password p-component p-inputwrapper p-input-icon-right"
            [style]="{ width: '100%' }"
            [inputStyle]="{ width: '100%' }"
            [toggleMask]="true"
            formControlName="password2"
            [ngClass]="{
              'ng-invalid ng-dirty':
                !createUserForm.controls.password2.valid &&
                createUserForm.controls.password2.touched
            }"
          />
          <small
            class="text-red-500"
            *ngIf="
              !createUserForm.controls.password2.valid &&
              createUserForm.controls.password2.touched
            "
          >
            {{ getPassword2Error() }}
          </small>
        </div>
        <h2 class="text-xl font-semibold">Podmioty</h2>
        <p-pickList
          [source]="allCredentials"
          [target]="credentials"
          sourceHeader="Dostępne"
          targetHeader="Wybrane"
          [dragdrop]="true"
          [responsive]="true"
          [sourceStyle]="{ height: '30rem' }"
          [targetStyle]="{ height: '30rem' }"
          filterBy="name"
          sourceFilterPlaceholder="Szukaj..."
          targetFilterPlaceholder="Szukaj..."
          breakpoint="1400px"
        >
          <ng-template let-credential pTemplate="item">
            <div class="flex flex-wrap p-2 align-items-center gap-3">
              <img
                class="w-8 h-8"
                src="https://ui-avatars.com/api/?size=128&background=random&name={{
                  credential.name
                }}"
                alt="{item.name}"
              />
              <div class="flex-1 flex flex-column gap-2">
                <span class="font-bold">{{ credential.name }}</span>
              </div>
            </div>
          </ng-template>
        </p-pickList>
        <p class="text-red-500" *ngIf="createUserFormError">{{ createUserFormError }}</p>
      </form>
    </div>
    <div class="flex gap-2">
      <p-button
        (onClick)="saveUserForm()"
        [disabled]="!createUserForm.valid"
        type="button"
        label="Zapisz"
        [loading]="createUserFormLoading"
      />
    </div>
  </div>
</div>
<div *ngIf="activeIndex === 4" class="flex flex-col gap-2 items-center">
  <img class="logo" src="../../../assets/logo.png" alt="BDO mobile logo" />
  <h2 class="title">Dziękujemy!</h2>
  <p>
    Konfiguracja przebiegła pomyślnie. Teraz możesz pełnić korzystać z konta BDO mobile. Jeśli jeszcze tego nie zrobiłeś, pobierz naszą aplikację mobilną na swój telefon i przekonaj się, jak działa.
  </p>
  <a
  rel="noopener"
  class="max-w-xs"
  target="_blank"
  href="https://play.google.com/store/apps/details?id=com.mobiletract.bdo&hl=pl_PL&gl=PL&utm_source=webpanel&utm_campaign=first_configuration&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
  ><img
    alt="pobierz z Google Play"
    src="https://play.google.com/intl/en_us/badges/static/images/badges/pl_badge_web_generic.png"
/></a>
  <p-button
        styleClass="self-center"
        (onClick)="ref.close()"
        type="button"
        >
        Zakończ
      </p-button>
</div>
