import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AccountValidators, ClientSecretsValidators } from 'src/app/services/bdoservice/constants';
import { BdoCredentials } from 'src/app/services/bdoservice/users/models/bdo-credentials.model';
import { Profile } from 'src/app/services/bdoservice/users/models/profile.model';
import { UsersService } from 'src/app/services/bdoservice/users/users.service';
import { AddBdoCredentialsRequest } from 'src/app/services/bdoservice/users/models/add-bdo-credentials-request.model';
import { EmployeeRequest } from '../users/users/users.component';
import { MarketingValidator } from 'src/app/common/validators/marketing-validator/marketing.validator';
import { InitCompanyAccountRequest } from 'src/app/services/bdoservice/users/models/init-company-account.model';
import { AddUserDialogData } from '../users/add-dialog/add-dialog.component';
import { MenuItem } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { KeycloakValidators } from 'src/app/common/validators/keycloak-validators/keycloak-validators';
import { ConfirmedValidator } from 'src/app/common/validators/confirmed-password-validator/confirmed-password.validator';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-first-configuration',
  templateUrl: './first-configuration.component.html',
})
export class FirstConfigurationComponent implements OnInit {

  profile: Profile

  credentialsForm: FormGroup;
  credentialsFormError = '';

  createUserForm: FormGroup;
  createUserFormError = '';

  passwordHide = true;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  allCredentials: BdoCredentials[] = [];
  credentials: BdoCredentials[] = [];

  marketingSource = [
    "Facebook",
    "Google",
    "Ekowitryna.pl",
    "Inne"
  ];

  accountForm: FormGroup = new FormGroup({
    companyName: new FormControl(''),
    marketingAgreement: new FormControl(false),
    marketingSource: new FormControl(this.marketingSource[0], [Validators.required]),
    marketingSourceOther: new FormControl(''),
  }, { validators: [MarketingValidator('marketingSource', 'marketingSourceOther')] });

  accountFormError = '';
  accountFormLoading = false;

  createUserFormLoading = false;

  items: MenuItem[] = [
    { label: 'Witamy w BDO Mobile!', icon: 'pi pi-fw pi-home' },
    { label: 'Dane podstawowe', icon: 'pi pi-fw pi-pencil' },
    { label: 'Dodaj podmiot', icon: 'pi pi-fw pi-id-card' },
    { label: 'Stwórz użytkownika mobilnego', icon: 'pi pi-fw pi-user-plus' },
    { label: 'Koniec', icon: 'pi pi-fw pi-check' }
  ];

  activeIndex = 0;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay(1)
    );

  constructor(
    public readonly ref: DynamicDialogRef,
    private usersService: UsersService,
    private breakpointObserver: BreakpointObserver,
    private accountService: AccountService,
    ) { }

  ngOnInit(): void {
    this.createUserForm = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      username: new FormControl('', [Validators.required, Validators.pattern(AccountValidators.USERNAME_PATTERN)]),
      password: new FormControl('', [Validators.required, Validators.minLength(AccountValidators.MIN_PASSWORD_LENGTH), Validators.maxLength(AccountValidators.MAX_PASSWORD_LENGTH), KeycloakValidators.passwordValidator()]),
      password2: new FormControl('', [Validators.required]),
      marketingSourceOther: new FormControl(''),
    }, { validators: [ConfirmedValidator('password', 'password2', false)] });

    this.credentialsForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(AccountValidators.MIN_CREDENTIALS_NAME_LENGTH)]),
      clientId: new FormControl('', [Validators.required, Validators.pattern(ClientSecretsValidators.CLIENT_ID_PATTERN)]),
      clientSecret: new FormControl('', [Validators.required, Validators.pattern(ClientSecretsValidators.CLIENT_SECRET_PATTERN)]),
    });

    this.accountService.profile$.subscribe(res => {
      if(res.data.credentials){
        this.createUserForm.get('credentials').patchValue(res.data.credentials);
      }
    });
  }

  onNext() {
    if (this.activeIndex < this.items.length - 1) {
      this.activeIndex += 1;
    }
  }


  saveAccountForm() {
    this.accountFormError = null;
    this.accountFormLoading = true;
    this.initAccount();

    this.activeIndex = 0;
  }

  private initAccount() {
    this.accountFormLoading = true;

    const request = new InitCompanyAccountRequest(
      this.accountForm.controls.companyName.value,
      this.accountForm.controls.marketingAgreement.value,
      this.accountForm.controls.marketingSource.value,
      this.accountForm.controls.marketingSourceOther.value,

    )
    this.usersService.initAccount(request).then(() => {
      this.activeIndex = 2
    })
      .catch(err => {
        this.credentialsFormError = err.message || 'Wystąpił nieoczekiwany błąd.'
      })
      .finally(() => {
        this.accountFormLoading = false;
      });
  }

  getMarketingSourceOtherError(): string {
    const field = this.accountForm.get('marketingSourceOther');
    if (field.hasError('required')) {
      return 'Pole jest wymagane.';
    } else if (field.hasError('marketingValidator')) {
      return 'Pole jest wymagane';
    } else {
      return '';
    }
  }

  getCompanyNameError(): string {
    const field = this.accountForm.get('companyName');
    return field.hasError('required') ? 'Pole jest wymagane.' : '';
  }

  saveCredentialsForm() {
    this.credentialsFormError = null
    this.saveCredential(this.credentialsForm.getRawValue());
  }

  private saveCredential(data: BdoCredentials) {
    const request = new AddBdoCredentialsRequest(
      data.name,
      data.clientId.trim(),
      data.clientSecret.trim()
    )

    this.usersService.addBdoCredentials(request).then(() => {
      this.accountService.loadProfile();
      this.activeIndex = 3;

    })
      .catch(err => {
        this.credentialsFormError = err.message || 'Wystąpił nieoczekiwany błąd.'
      });
  }

  getCredentialsNameError(): string {
    const field = this.credentialsForm.get('name');

    if (field.hasError('required')) {
      return 'Pole jest wymagane.';
    }

    if (field.hasError('minlength')) {
      return `Pole musi składać się z min. ${AccountValidators.MIN_CREDENTIALS_NAME_LENGTH} znaków.`;
    }

    return '';
  }

  getClientIdError(): string {
    const field = this.credentialsForm.get('clientId');

    if (field.hasError('required')) {
      return 'Pole jest wymagane.';
    }

    if (field.hasError('pattern')) {
      return 'Pole musi być wypełnione w odpowiednim formacie.';
    }

    return '';
  }

  getClientSecretError(): string {
    const field = this.credentialsForm.get('clientSecret');

    if (field.hasError('required')) {
      return 'Pole jest wymagane.';
    }

    if (field.hasError('pattern')) {
      return 'Pole musi być wypełnione w odpowiednim formacie.';
    }

    return '';
  }


  saveUserForm() {
    this.createUserFormError = null;
    this.createUserFormLoading = true;
    this.saveEmployee(this.createUserForm.getRawValue());
  }

  saveEmployee(data: AddUserDialogData) {
    const employee = new EmployeeRequest(
      data.firstName,
      data.lastName,
      data.username.toLocaleLowerCase(),
      data.password,
      data.credentials?.map((credential) => { return credential.id })
    );

    this.usersService.saveEmployee(employee).then(() => {
      this.activeIndex = 4;
      this.scrollToTop();
    })
      .catch(err => {
        this.createUserFormError = err.message || "Wystąpił nieoczekiwany błąd podczas zakładania użytkownika"
      })
      .finally(() => {
        this.createUserFormLoading = false;
      });
  }

  getFirstNameError(): string {
    const field = this.createUserForm.get('firstName');
    return field.hasError('required') ? 'Pole jest wymagane.' : '';
  }

  getLastNameError(): string {
    const field = this.createUserForm.get('lastName');
    return field.hasError('required') ? 'Pole jest wymagane.' : '';
  }

  getUsernameError(): string {
    const field = this.createUserForm.get('username');

    if (field.hasError('required')) {
      return 'Pole jest wymagane.';
    }

    if (field.hasError('pattern')) {
      return 'Nazwa użytkownika musi się składać z liter, cyfr oraz podkreślnika lub kropki.';
    }

    return '';
  }

  getEmailError(): string {
    const field = this.createUserForm.get('email');

    if (field.hasError('required')) {
      return 'Pole jest wymagane.';
    }

    if (field.hasError('email')) {
      return 'Nieprawidłowy adres e-mail.';
    }

    return '';
  }

  getPasswordError(): string {
    const field = this.createUserForm.get('password');

    if (field.hasError('required')) {
      return 'Pole jest wymagane.';
    }

    if (field.hasError('minlength')) {
      return `Pole musi składać się z min. ${AccountValidators.MIN_PASSWORD_LENGTH} znaków.`;
    }

    if (field.hasError('uppercase')) {
      return 'Pole musi zawierać co najmniej jedną wielką literę.';
    }

    if (field.hasError('specialCharacter')) {
      return 'Pole musi zawierać co najmniej jeden znak specjalny.';
    }

    return '';
  }

  getPassword2Error(): string {
    const field = this.createUserForm.get('password2');

    if (field.hasError('required')) {
      return 'Pole jest wymagane.';
    }

    if (field.hasError('confirmedValidator')) {
      return "Hasła nie są identyczne.";
    }

    return '';
  }

  isRequired(formGroup: FormGroup, field: string): boolean {
    return formGroup.get(field)?.hasValidator(Validators.required);
  }

  onConfirm(): void {
    this.ref.close();
  }

  skipCredentialsForm() {
    this.activeIndex = 3;
    this.scrollToTop();
  }

  scrollToTop() {
    const dialogContentElement = document.querySelector('.p-dialog .p-dialog-content');
    if (dialogContentElement) {
      dialogContentElement.scrollTop = 0;
    }
  }

}
