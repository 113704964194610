<app-splashscreen *ngIf="isInitializing"></app-splashscreen>
<!-- app.component.html -->
<div class="flex flex-col h-full lg:flex-row">

  <!-- Main content area with conditional padding for desktop -->
  <div class="flex-grow flex flex-col w-full">
    <!-- Padding for top bar on mobile -->
    <div class="h-16"></div>
    <!-- Permanent sidebar for desktop -->
    <div class="hidden lg:flex fixed inset-y-0 left-0 bg-white dark:bg-gray-950 shadow-lg z-[30]" [ngClass]="{ 'transition-width duration-300 ease-in-out': !isResizing }"
    [ngStyle]="{'width.px': sidebarWidth}"

    (mouseleave)="onMouseLeaveSidebar()"
    >
    <!-- Fixed sidebar content for desktop goes here -->
    <!-- Sidebar Menu List -->
    <div (mouseenter)="temporarilyShowSidebar()"  class="flex flex-col gap-4 mt-16 lg:mt-32 overflow-auto w-full text-gray-700 text-lg">

      <app-menu *ngIf="!isCollapsed || sidebarWidth === MAX_WIDTH" [items]="menuItems"></app-menu>
      <app-sidebar-tile *ngIf="!isCollapsed || sidebarWidth === MAX_WIDTH" [packageInfo]="activePackage"></app-sidebar-tile>

    </div>


    <!-- Resizer Handle -->
    <div class="absolute top-0 w-0.5 hover:bg-slate-400 right-0 bottom-0 cursor-col-resize transition-width duration-500 ease-in-out"
      (mousedown)="startResizing($event)"
      (mouseenter)="showToggleButton = true"

       ></div>

      <!-- Toggle Button -->
       <button *ngIf="showToggleButton" class="absolute top-40 -right-4 z-9999  p-1 w-8 h-8 rounded-full border border-gray-200 dark:border-slate-700 bg-white shadow-lg hover:bg-slate-500 dark:bg-gray-800 dark:hover:bg-gray-900 text-slate-600 hover:text-white dark:hover:text-slate-400 cursor-pointer transition-width duration-500 ease-in-out"
       (click)="toggleSidebar()">
     <i class="pi pi-chevron-left" *ngIf="!isCollapsed"></i>
     <i class="pi pi-chevron-right" *ngIf="isCollapsed"></i>
     </button>
    </div>
    <!-- Main content area with dynamic padding for desktop -->
    <div class="flex-grow flex flex-col" [ngStyle]="(isHandset$ | async) === false ? {'margin-left.px': sidebarWidth} : {}" [ngClass]="{ 'transition-width duration-300 ease-in-out': !isResizing }">
    <!-- Responsive top bar -->

    <!-- Padding for top bar on mobile -->
    <div class="h-16 lg:h-24"></div>

    <!-- Responsive top bar -->
    <div class="flex-grow bg-white dark:bg-gray-950 shadow-sm fixed inset-x-0 top-0 z-[40] flex justify-between items-center p-2 lg:p-6 border-b border-gray-200 dark:border-gray-800">
      <div class="hidden lg:flex items-center gap-4">
        <img src="../../assets/logo.svg" class="w-16 h-16 mx-auto" alt="BDO mobile" />
        <p class="font-semibold text-2xl dark:text-white">Panel klienta</p>
      </div>
      <!-- Menu button for mobile -->
      <button (click)="sidebarVisible = true" class="menu-button lg:hidden">
        <i class="pi pi-bars text-xl"></i>
      </button>
      <!-- Logo centered for mobile, hidden on desktop -->
      <div class="flex-grow lg:flex-grow-0 lg:hidden text-center">
        <img src="../../assets/logo.svg" class="w-16 h-16 mx-auto" alt="BDO mobile" />
      </div>
      <!-- Spacer to push icons to the right on desktop, hidden on mobile -->
      <div class="hidden lg:flex flex-grow"></div>
      <!-- Icons for settings, notifications, and night mode (desktop only) -->
      <div class="flex items-center">
        <!-- Settings Button -->
        <p-button pRipple type="button" icon="pi pi-cog" class="p-button-rounded p-button-text mx-2" pTooltip="Ustawienia" (onClick)="navigateToSettings()" [text]="true"></p-button>
        <!-- Night Mode Toggle Button -->
        <p-button pRipple type="button" [icon]="themeService.isDarkMode ? 'pi pi-sun' : 'pi pi-moon'" class="mx-2" (onClick)="toggleNightMode()" [pTooltip]="themeService.isDarkMode ? 'Zmień na tryb jasny' : 'Zmień na tryb ciemny'" tooltipPosition="top" [text]="true"></p-button>
        <p-button pRipple  type="button" icon="pi pi-sign-out" styleClass="lg:hidden" severity="danger" (onClick)="logout()" pTooltip="Wyloguj" tooltipPosition="top" [text]="true"></p-button>
      </div>
      <!-- Profile section with separator (desktop only) -->
      <div class="hidden lg:flex items-center border-l pl-4">
        <!-- Avatar with initials -->
        <p-avatar image="https://ui-avatars.com/api/?size=128&background=random&name={{getFullName() }}" shape="circle"></p-avatar>
        <!-- Name and Email -->
        <div class="hidden lg:block text-left ml-2">
          <div class="font-semibold dark:text-white">{{ getFullName() }}</div>
          <div class="text-sm text-gray-400">{{ profile?.email }}</div>
        </div>

        <p-button pRipple  type="button" icon="pi pi-sign-out" severity="danger" (onClick)="logout()" pTooltip="Wyloguj" tooltipPosition="top" [text]="true"></p-button>
      </div>
    </div>


    <!-- Scrollable Main content -->
    <div class="flex-grow overflow-auto p-4 lg:pt-0">
      <router-outlet></router-outlet>
    <p-button  pRipple *ngIf="scrolled" icon="pi pi-arrow-up" class="fixed bottom-4 justify-center right-4 z-50  w-10 h-10 rounded-full border border-gray-200 dark:border-slate-700 bg-white shadow-lg hover:bg-slate-500 dark:bg-gray-800 dark:hover:bg-gray-900 text-slate-600 hover:text-white dark:hover:text-slate-400 cursor-pointer transition-width duration-500 ease-in-out" (onClick)="scrollToTop()" [ngClass]="{'block': scrolled, 'hidden': !scrolled}"></p-button>
      <!-- Footer -->
    <div class="app-version p-2 text-center text-xs bottom-0">
      <span>BDO mobile v.{{ appVersion }}</span>
    </div>
  </div>


  </div>

<!-- Sliding sidebar for mobile -->
<p-sidebar [(visible)]="sidebarVisible" [baseZIndex]="10000" [modal]="isHandset$ | async" class="w-80" [showCloseIcon]="isHandset$ | async">
  <app-menu [items]="menuItems"></app-menu>
  <ng-template pTemplate="header">
    <div class="flex gap-2 justify-between items-center">
      <img src="../../assets/logo.svg" class="w-16 h-16" alt="BDO mobile" />
      <p class="font-semibold text-2xl">Panel klienta </p>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <app-sidebar-tile [packageInfo]="activePackage"></app-sidebar-tile>
  </ng-template>
</p-sidebar>

