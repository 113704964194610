<div
  class="flex flex-col md:flex-row gap-4 z-40 relative"
>
  <!-- Wybór podmiotu -->
  <div class="flex flex-col flex-wrap gap-2 m-2 items-start">
    <p class="font-medium dark:text-white">Podmiot:</p>
    <p-skeleton
      *ngIf="!companyProfile"
      class="w-[200px]"
      height="3rem"/>
    <p-dropdown
      *ngIf="companyProfile?.credentials"
      autoWidth="false"
      class="w-full md:w-fit"
      [options]="companyProfile.credentials"
      [(ngModel)]="selectedBdoCredentials"
      optionLabel="name"
      [style]="{ width: '100%', 'min-width': '200px' }"
      (onChange)="onChangeBdoCredentials($event)"
      [filter]="true"
      [disabled]="disabled"
      filterBy="name"
    >
      <ng-template pTemplate="selectedItem">
        <div
          class="flex align-items-center gap-2"
          *ngIf="selectedBdoCredentials"
        >
          <img
            src="https://ui-avatars.com/api/?size=128&background=random&name={{
              selectedBdoCredentials?.name
            }}"
            style="width: 18px"
            [alt]="selectedBdoCredentials?.name"
          />
          <div>{{ selectedBdoCredentials.name }}</div>
        </div>
      </ng-template>
      <ng-template let-credentials pTemplate="item">
        <div class="flex align-items-center gap-2">
          <img
            src="https://ui-avatars.com/api/?size=128&background=random&name={{
              credentials?.name
            }}"
            style="width: 18px"
            [alt]="credentials?.name"
          />
          <div>{{ credentials.name }}</div>
        </div>
      </ng-template>
    </p-dropdown>
  </div>
  <!-- Wybór miejsca prowadzenia działalności -->
  <div class="flex flex-col flex-wrap gap-2 m-2 items-start">
    <p class="font-medium dark:text-white">Miejsce prowadzenia działalności:</p>
    <div class="flex flex-row flex-wrap items-center gap-2">
      <p-skeleton
      *ngIf="!eups"
      class="w-[200px]"
      height="3rem"/>
      <div *ngIf="eups" class="flex flex-col gap-2" >
      <p-dropdown
        class="w-full md:w-fit"
        [options]="eups"
        [disabled]="!selectedBdoCredentials || !eups || eups.length === 0 || disabled"
        optionLabel="name"
        [style]="{ width: '100%' }"
        (onChange)="onChangeBdoEup($event)"
        [filter]="true"
        filterBy="name"
        [(ngModel)]="selectedEup"
        [disabled]="selectedEupError"
        >
        </p-dropdown>
        <small class="text-red-500" *ngIf="selectedEupError">
          {{ selectedEupError }}
        </small>
      </div>
      <div class="flex gap-2 w-full md:w-fit">
        <p-inputSwitch
          class="ml-2"
          [disabled]="disabled"
          id="hideInactiveEups"
          (onChange)="onChangeHideInactiveEups($event)"
          [(ngModel)]="hideInactiveEups"
        />
        <label for="hideInactiveEups" class="dark:text-white">Ukryj nieaktywne MPD</label>
      </div>
    </div>
  </div>

  <!-- Wybór roli -->
  <div *ngIf="roleSelectable" class="flex flex-col flex-wrap gap-2 m-2 items-start">
    <p class="font-medium dark:text-white">Rola:</p>
    <p-dropdown
      [disabled]="disabled"
      [options]="filteredRoles"
      optionLabel="label"
      class="w-full md:w-fit"
      [style]="{ width: '100%', 'min-width': '200px' }"
      (onChange)="onChangeRole($event)"
      [(ngModel)]="selectedRole"
    >
    </p-dropdown>
  </div>
</div>
