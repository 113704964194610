export class AddBdoCredentialsRequest{
  private name: string
  private clientId: string
  private clientSecret: string

  constructor(name: string, clientId: string, clientSecret: string){
    this.name = name;
    this.clientId = clientId;
    this.clientSecret = clientSecret;
  }
}
